body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #fff; */
}

* {
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .btn {
    @apply text-white  bg-primary px-6 py-2 rounded-md duration-300 cursor-pointer;
  }
  .btn:hover {
    @apply bg-primary2;
  }
  .btn-alt {
    @apply text-white bg-blueAlt  px-6 py-2 rounded-md duration-300 cursor-pointer;
  }
  .btn-alt:hover {
    @apply bg-second text-white;
  }
  .section-heading {
    @apply text-4xl  text-blue leading-tight font-bold tracking-wide text-center;
  }
  .section-subHeading {
    @apply text-altBlack text-2xl py-8 font-medium text-center;
  }
  .input-form {
    @apply block w-full px-4 py-5 text-base font-normal text-black  bg-clip-padding
               bg-altSecond rounded-lg transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue focus:outline-none;
  }
}

#homepage {
  background-image: url("./assets/images/banner2.png");
  background-position: center center;
  background-size: cover;
}
#homepage2 {
  background-image: url("./assets/images/bg-line.png");
  background-position: bottom center;
  background-size: 140vw 100vh;
}
#contact {
  background-image: url("./assets/images/map.png");
  background-size: contain;
  padding: 100px 0;
}
#bookCover {
  background-image: url("./assets/images/book_cover.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/* .slider {
  margin-right: 20px;
} */

#classes {
  background-image: url("./assets/images/banner-heading.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#news {
  background: linear-gradient(to bottom, #fff 35%, #bee2ed 0%);
}

#events .slick-slide {
  padding-right: 80px;
}

@media only screen and (max-width: 850px) {
  #events .slick-slide {
    padding-right: 30px;
  }
}

.slick-slide {
  padding-right: 30px;
  padding-left: 30px;
}

.slick-dots li {
  width: 30px;
  height: 30px;
}
.slick-dots li button:before {
  top: 60px !important;
}
.slick-dots li.slick-active button:before {
  font-size: 17px;
  top: 60px !important;
}

#testimonials .slick-active .cont {
  background: #00a1cf;
}
#testimonials .slick-active .cont .quote-icon {
  color: #42b9db;
}
#testimonials .slick-active .cont p,
#testimonials .slick-active .cont .down-icon {
  color: #fff;
}
@media only screen and (max-width: 650px) {
  section {
    padding-left: 15px;
    padding-right: 15px;
  }
  /* .container {
    padding-left: 10px;
    padding-right: 10px;
  } */
  .slick-dots li button:before {
    top: 0px !important;
  }
  .slick-dots li.slick-active button:before {
    font-size: 17px;
    top: 0px !important;
  }
  .slick-slide {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.slick-prev {
  font-size: 40px !important;
  height: 60px !important;
  width: 60px !important;
  color: #1f1f1f !important;
  z-index: 999999;
}
.slick-next {
  font-size: 60px !important;
  height: 60px !important;
  width: 60px !important;
  color: #1f1f1f !important;
  z-index: 999999;
}
